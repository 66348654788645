<template>
  <v-app>
    <!-- alert  -->
    <div>
      <v-alert
        v-model="alert"
        border="left"
        dark
        dismissible
        class="alert"
        :class="{
          'alert-error': alertType == 'error',
          'alert-success': alertType == 'success'
        }"
      >
        {{ alertMessage }}
      </v-alert>
    </div>
    <!-- end of alert  -->
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <div class="mb-8 mt-8 text-center">
          <h1>Salary Period</h1>
        </div>

        <v-data-table loading-text="Loading salaryPeriods... Please wait"
                      :loading="isLoading"
                      :headers="salaryPeriodHeader"
                      :items="salaryPeriods">
          <template v-slot:item.actions="{ item }">
            <div class="d-flex">
              <Button :disabled="item.status > 0 && item.status <=2" :btnType="'Submit'" :label="'Close'" class="mr-4" :isLoading="isCloseLoading.id=== item.id" @onClick="close(item)" />
              <Button :disabled="item.status > 0 && item.status === 2" :label="'Process'" :btnType="'Cancel'" :isLoading="isProcessLoading.id === item.id" @onClick="process(item)" />
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
  </v-app>
</template>
<script>
import Button from '@/components/ui/form/Button.vue'
import { salaryPeriodService } from '@/services'
export default {
  components: {
    Button
  },
  data () {
    return {
      alertMessage: '',
      alert: false,
      alertType: '',
      valid: false,
      isProcessLoading: {
        id: '',
        status: ''
      },
      isCloseLoading: {
        id: '',
        status: ''
      },
      isLoading: false,
      salaryPeriods: [],
      salaryPeriodHeader: [
        {
          text: 'Description',
          value: 'description'
        },
        {
          text: 'Start Date',
          value: 'startDate'
        }, {
          text: 'End Date',
          value: 'endDate'
        },
        {
          text: 'Month',
          value: 'month'
        },
        {
          text: 'Year',
          value: 'year'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    }
  },
  computed: {

  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => { this.alert = false }, 5000)
    },
    loadSalaryPeriod () {
      this.isLoading = true
      salaryPeriodService.getAllSalaryPeriod().then(result => {
        this.salaryPeriods = result.data
        this.isLoading = false
      }).catch(error => {
        console.log(error)
      })
    },
    close (item) {
      this.isCloseLoading.id = item.id
      this.isCloseLoading.status = true
      salaryPeriodService.closeSalaryPeriod(item.id).then(() => {
        this.showAlertMessage('Salary Period Close Succesfully', 'success')
        this.loadSalaryPeriod()
      }).catch(() => {
        this.showAlertMessage('Salary Period Close Failed', 'error')
      }).finally(() => {
        this.isCloseLoading.id = ''
        this.isCloseLoading.status = true
      })
    },
    process (item) {
      this.isProcessLoading.id = item.id
      this.isProcessLoading.status = true
      salaryPeriodService.processSalaryPeriod(item.id).then(() => {
        this.showAlertMessage('Salary Period Process Succesfully', 'success')
        this.loadSalaryPeriod()
      }).catch(() => {
        this.showAlertMessage('Salary Period Process Failed', 'error')
      }).finally(() => {
        this.isProcessLoading.id = ' '
        this.isProcessLoading.status = false
      })
    }
  },
  mounted () {
    this.loadSalaryPeriod()
  }
}

</script>
<style scoped>
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
